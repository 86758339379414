<template>
  <div class="highway">
    <div class="">
      <!-- <div class="tabs_case">
        <CButtons
          @Cbtns="fnGetCbtns"
          class="CARD_body_tabs"
          :dButtons1="dButtons1"
          :defaultVal="dButtons1_current" />
      </div> -->
      <div class="change_asset">
        <CSelect
          v-if="asset_name.length > 1"
          class="ss chart3_cascasder"
          @CSelect_select_val="assetSelect"
          :options="assetName"
          :auto="true"
          :clearable="false"
          :default="asset_default">
          选择资产:</CSelect
        >
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtns"
          class="ss CARD_body_tabs"
          :dButtons1="dButtons1"
          :disabled="false"
          :dButtons1_current="dButtons1_current" />
      </div>
      <MainPerformance
        ref="mainPerformance"
        :asset_default="asset_default"
        v-if="dButtons1_current == '主要表现'" />
      <MarketTrends
        ref="marketTrends"
        :asset_default="asset_default"
        v-if="dButtons1_current == '市场趋势'" />
    </div>
    <div class="CARD">
      <Calculator ref="calculator" :assetName="assetName" />
    </div>
  </div>
</template>

<script>
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
// import CButtons from '@/components/Basic/CButtons';
import CSelect from '@/components/Basic/CSelect';

import MainPerformance from './MainPerformance.vue';
import MarketTrends from './MarketTrends.vue';
import Calculator from './Calculator.vue';
export default {
  components: { CButtonsSeparate, CSelect, MainPerformance, MarketTrends, Calculator },
  props: {
    asset_name: Array,
  },
  data() {
    return {
      ROOTFONTFIZE: 0,

      dButtons1: ['主要表现', '市场趋势'],
      dButtons1_current: '主要表现',
      CODE: '',
      asset_default: '',
    };
  },
  created() {
    this.reload();
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
  },
  methods: {
    assetSelect(val) {
      this.asset_default = val;
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },
    fnGetCbtns(data) {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.dButtons1_current = data;
    },
    async reload() {
      this.asset_default = this.asset_name[0];
    },
  },
  computed: {
    assetName() {
      return this.asset_name.map((item) => ({
        label: item,
        value: item,
      }));
    },
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
    },
  },
};
</script>

<style lang="less" scoped>
.change_asset {
  display: flex;
  padding: 20px 0;
  .ss:first-child {
    margin-left: 0 !important;
  }
}

.highway {
  .CARD {
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background-color: var(--charts_bgc);
    padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    .tabs_case {
      padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
    }
  }
}
</style>
