<template>
  <div class="highway">
    <div class="">
      <!-- <div class="tabs_case">
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtns"
          class="CARD_body_tabs"
          :dButtons1="dButtons1"
          :disabled="false"
          :dButtons1_current="dButtons1_current" />
      </div> -->
      <div class="change_asset">
        <CSelect
          v-if="asset_name.length > 1"
          class="ss chart3_cascasder"
          @CSelect_select_val="changeAsset"
          :options="assetName"
          :auto="true"
          :clearable="false"
          :default="current_asset">
          选择资产:</CSelect
        >
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtns"
          class="ss CARD_body_tabs"
          :dButtons1="dButtons1"
          :disabled="false"
          :dButtons1_current="dButtons1_current" />
      </div>
      <MainPerformance
        ref="mainPerformance"
        v-if="dButtons1_current == '主要表现'"
        :current_asset="current_asset" />
      <MarketTrends
        ref="marketTrends"
        v-if="dButtons1_current == '市场趋势'"
        :current_asset="current_asset" />
    </div>
    <div class="CARD">
      <Calculator ref="calculator" :assetName="assetName" />
    </div>
  </div>
</template>

<script>
// import CButtons from '@/components/Basic/CButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CSelect from '@/components/Basic/CSelect';

import MainPerformance from './MainPerformance.vue';
import MarketTrends from './MarketTrends.vue';
import Calculator from './Calculator.vue';
export default {
  components: { CButtonsSeparate, MainPerformance, MarketTrends, Calculator, CSelect },
  props: {
    _asset_name: Array,
  },
  data() {
    return {
      current_asset: '',
      ROOTFONTFIZE: 0,
      dButtons1: ['主要表现', '市场趋势'],
      dButtons1_current: '主要表现',
      CODE: '',
    };
  },
  created() {},
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
  },
  methods: {
    changeAsset(v) {
      this.current_asset = v;
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },
    fnGetCbtns(data) {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.dButtons1_current = data;
    },
    reload() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
    },
  },
  computed: {
    asset_name() {
      return ['整体', ...this._asset_name];
    },
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
    assetName() {
      return this.asset_name.map((item) => ({
        label: item,
        value: item,
      }));
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
    },
    asset_name: {
      handler(now) {
        this.current_asset = now[0];
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.change_asset {
  display: flex;
  padding: 20px 0;
  .ss:first-child {
    margin-left: 0 !important;
  }
}

.highway {
  .CARD {
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background-color: var(--charts_bgc);
    padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    .tabs_case {
      padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
    }
  }
}
</style>
