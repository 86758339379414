<template>
  <div class="market_trends">
    <div class="downloadBtns">
      <CButtonsDownload :dButtonsDownloads="dButtonsDownloads" @fnButtondownload="fnButtonDL6_1" />
      <span style="display: none">
        <download-excel
          id="downloadExcel_ipo"
          :data="echart_data"
          :fields="json_fields"
          :name="overviewName + '_' + asset_default">
          <i class="el-icon-download"></i>
        </download-excel>
      </span>
    </div>
    <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
    <div
      class="chart"
      ref="chartRef"
      v-loading="loading"
      element-loading-text="数据量巨大，正在计算中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="width: 100%; height: 425px"></div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="chartRefDialog" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsDownload from '@/components/Basic/CButtons_download';
import html2canvas from 'html2canvas';
import { fnDownloadBgc } from '@/utils/util';
import CDialog from '@/components/Basic/CDialog';

export default {
  components: { CButtonsDownload, CDialog },
  props: {
    asset_default: String,
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.renderEchart();
    },
    asset_default(val) {
      this.reload();
    },
  },
  data() {
    return {
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      ROOTFONTFIZE: 0,
      loading: true,
      echart_data: [],
      CODE: '',
      dButtonsDownloads: [
        {
          id: '241072',
          name: '下载图片',
        },
        {
          id: '241073',
          name: '下载表格',
        },
      ],
      overviewName: '主要车流量同比增速',
      json_fields: {
        asset_name: 'asset_name',
        date: 'date',
        车流量同比: {
          field: 'vehd_yoy',
          callback: (value) => {
            return `${value}%`;
          },
        },
        车流量累计同比: {
          field: 'vehd_acc_yoy',
          callback: (value) => {
            return `${value}%`;
          },
        },
        路费收入同比: {
          field: 'vehd_rev_yoy',
          callback: (value) => {
            return `${value}%`;
          },
        },
        路费收入环比: {
          field: 'vehd_rev_acc_yoy',
          callback: (value) => {
            return `${value}%`;
          },
        },
      },
    };
  },
  created() {
    this.reload();
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    if (this.echart_dialog_instance) {
      this.echart_dialog_instance.dispose();
    }
    if (this.echart_instance) {
      this.echart_instance.dispose();
    }
  },
  methods: {
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.fnButtonDL6_4_1();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_ipo').click();
        }, 0);
      }
    },
    fnButtonDL6_4_1() {
      let h = this.$refs.chartRef.scrollHeight;
      let w = this.$refs.chartRef.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartRef, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = this.overviewName; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    reload() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.loadingData();
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
      this.echart_dialog_instance && this.echart_dialog_instance.resize();
      this.echart_instance && this.echart_instance.resize();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },
    async loadingData() {
      this.loading = true;
      const { data } = await this.$https.get(
        '/api/v2/get_operation_expressway?code=' + this.CODE + '&asset_name=' + this.asset_default
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);
      const echart_data = (data.data || {}).list || [];
      if (!echart_data.length) {
        this.$message.info('暂无数据');
      }
      const date = (item) => {
        return `${item.year}/${item.month}`;
      };
      this.echart_data = echart_data
        .map((item) => ({
          ...item,
          date: `${item.year}年${item.month}月`,
        }))
        .sort((a, b) => {
          return new Date(date(a)).getTime() - new Date(date(b)).getTime();
        });
      setTimeout(() => {
        this.renderEchart();
      }, 0);
    },
    renderEchart(flag) {
      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.chartRefDialog);
        }
        this.echart_dialog_instance.clear();
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.chartRef);
      }
      !flag && this.echart_instance.clear();

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      const item_length = this.echart_data.length;
      const font_r = item_length > 30 ? 30 / item_length : 1;
      const legendMap = {
        vehd_yoy: '车流量同比',
        vehd_acc_yoy: '车流量累计同比',
        vehd_rev_yoy: '路费收入同比',
        vehd_rev_acc_yoy: '路费收入环比',
      };
      const max = this.echart_data.length;
      const endValue = max - 11;
      const startValue = max;
      const options = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        color: ['#65e7e9', '#9deff1', '#5e63e6', '#8186eb'],
        title: [
          {
            text: this.overviewName,
            textStyle: {
              // 标题文字样式设置
              color: OPTIONDATA.title.textStyle.color,
              fontSize: OPTIONDATA.title.textStyle.fontSize,
            },
            left: 'center',
            top: OPTIONDATA.title.top,
          },
        ],
        legend: {
          left: 'center',
          bottom: this.ROOTFONTFIZE * (28 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          formatter: function (name) {
            return legendMap[name];
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          triggerOn: 'mousemove|click',
          formatter: (data) => {
            return data
              .map(({ marker, value, seriesName }) => {
                return (
                  marker +
                  `<span style="margin-left:6px">${legendMap[seriesName]}：${value[seriesName]}%</span>`
                );
              })
              .join('<br />');
          },
        },
        dataset: {
          dimensions: ['date', 'vehd_yoy', 'vehd_acc_yoy', 'vehd_rev_yoy', 'vehd_rev_acc_yoy'],
          source: this.echart_data,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: flag && this.popUpWindowIndex === 1 ? 45 : 0,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize * (flag ? font_r : 1),
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          splitLine: OPTIONDATA.yAxis.splitLine,
          axisLabel: {
            formatter: (v) => {
              return v + '%';
            },
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          { type: 'bar', barGap: 0 },
          { type: 'bar', barGap: 0 },
          { type: 'bar', barGap: 0 },
          { type: 'bar', barGap: 0 },
        ],
        grid: {
          top: this.ROOTFONTFIZE * (50 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          containLabel: true,
        },
        dataZoom: {
          // 开启数据平移qc
          type: 'slider', // 滑动条单独显示
          show: this.echart_data.length > 12, // 是否显示滑动条
          filterMode: 'empty',
          startValue, // 展示区域内容的起始数值
          endValue, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
          bottom: this.ROOTFONTFIZE * (2 / 144), // 距离图表区域下边的距离
          showDetail: false, // 拖拽时是否显示详情
          showDataShadow: false,
          fillerColor: '#D4D4D4', // 平移条的填充颜色
          borderColor: 'transparent', // 边框颜色
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
          // 通过该属性可以只滑动，不显示缩放功能
          handleStyle: {
            opacity: 0,
          },
          lineStyle: {
            opacity: 0,
          },
          textStyle: {
            fontSize: 0,
          },
        },
      };
      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
        return;
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.renderEchart(true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.renderEchart(true);
      });
    },
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
};
</script>

<style lang="less" scoped>
.downloadBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 33px;
}
.market_trends {
  position: relative;
}
.icon-fangda2 {
  position: absolute;
  right: 82px;
  top: 48px;
}
</style>
