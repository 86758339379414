<template>
  <div>
    <CButtonsDownload :dButtonsDownloads="dButtonsDownloads" @fnButtondownload="fnButtonDL6_1" />
    <span style="display: none">
      <download-excel
        :id="echartRefName + 'excel'"
        :data="excel_info.excel_data"
        :fields="excel_info.json_fields"
        :name="excel_info.overviewName">
        <i class="el-icon-download"></i>
      </download-excel>
    </span>
  </div>
</template>

<script>
import CButtonsDownload from './CButtons_download';

import { fnDownloadBgc } from '../../utils/util';

import html2canvas from 'html2canvas';

export default {
  components: { CButtonsDownload },
  props: {
    // {
    //   overviewName:String,
    //   json_fields:Object,
    //   excel_data:Array
    // }
    // 全部必传
    excel_info: Object,
    // 之前没有考虑到 echart容器嵌套在其他组件中的情况
    echartRefName: [String, Function],
    dButtonsDownloads: Array,
  },
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == 'dark',
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
    excelElClassName() {
      return typeof this.echartRefName === 'function'
        ? this.echartRefName().id
        : this.echartRefName;
    },
  },
  watch: {
    currentTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark';
    },
  },
  methods: {
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.fnButtonDL6_4_1();
      } else {
        setTimeout(() => {
          document.getElementById(this.echartRefName + 'excel').click();
        }, 0);
      }
    },
    fnButtonDL6_4_1() {
      let el = null;
      if (typeof this.echartRefName === 'function') {
        el = this.echartRefName().el;
      } else {
        el = this.$parent.$refs[this.echartRefName];
      }
      console.clear();
      console.log(el);
      let h = el.scrollHeight;
      let w = el.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(el, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = this.excel_info.overviewName; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
  },
};
</script>

<style></style>
