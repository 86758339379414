<template>
  <div class="main_performance">
    <div class="title">
      <div class="downloadBtns">
        <CButtonsDownload
          :dButtonsDownloads="dButtonsDownloads"
          @fnButtondownload="fnButtonDL6_1" />
        <span style="display: none">
          <download-excel
            id="downloadExcel_hway"
            :data="echart_data"
            :fields="json_fields"
            :name="overviewName + '_' + asset_default">
            <i class="el-icon-download"></i>
          </download-excel>
        </span>
      </div>
    </div>
    <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
    <div
      v-loading="loading"
      element-loading-text="数据量巨大，正在计算中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      id="main_performance"
      ref="main_performance"
      style="width: 100%; height: 425px"></div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="chartRefDialog" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import { fnDownloadBgc } from '@/utils/util';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import html2canvas from 'html2canvas';
import CDialog from '@/components/Basic/CDialog';

export default {
  components: { CButtonsDownload, CDialog },
  props: {
    asset_default: String,
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();
      this.getOperationExpressway();
    },
    asset_default(val) {
      this.reload();
    },
  },
  data() {
    return {
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

      ROOTFONTFIZE: 0,
      CODE: '',
      loading: false,
      dStackingReturnData2_X: [],
      dStackingReturnData2_JG: [],
      dStackingReturnData2_FH: [],
      dStackingReturnData2_RS: [],
      dButtonsDownloads: [
        {
          id: '241072',
          name: '下载图片',
        },
        {
          id: '241073',
          name: '下载表格',
        },
      ],
      json_fields: {
        date: 'date',
        asset_name: 'asset_name',
        '当月日均收费车流量(辆次)': 'vehd',
        '当月路费收入(人民币,万元,含增值税)': 'veh_rev',
        单日单公里单车收入: 'vehd_rev_perkil_percar',
      },
      overviewName: '主要车流量概览',
      echart_data: [],
    };
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
    this.reload();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);

    if (this.echart_dialog_instance) {
      this.echart_dialog_instance.dispose();
    }
    if (this.echart_instance) {
      this.echart_instance.dispose();
    }
  },
  methods: {
    async getOperationExpressway() {
      this.loading = true;
      const { data } = await this.$https.get(
        '/api/v2/get_operation_expressway?code=' + this.CODE + '&asset_name=' + this.asset_default
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);

      this.dStackingReturnData2_X = [];
      this.dStackingReturnData2_JG = [];
      this.dStackingReturnData2_FH = [];
      this.dStackingReturnData2_RS = [];

      var list = data.data.list || [];
      list = list
        .map((item) => ({
          ...item,
          date: `${item.year}年${item.month}月`,
        }))
        .sort((a, b) => {
          return (
            new Date(a['year'] + '/' + a['month']).getTime() -
            new Date(b['year'] + '/' + b['month']).getTime()
          );
        });

      let dataTmp = data.data.list;
      this.echart_data = list;
      if (!this.echart_data.length) {
        this.$message.info('暂无数据');
      }

      for (let index = 0; index < dataTmp.length; index++) {
        this.dStackingReturnData2_X.push(
          dataTmp[index]['year'] + '年' + dataTmp[index]['month'] + '月'
        );
        this.dStackingReturnData2_JG.push(dataTmp[index]['vehd']);
        this.dStackingReturnData2_FH.push(Number(dataTmp[index]['veh_rev']) / 10000);
        this.dStackingReturnData2_RS.push(dataTmp[index]['vehd_rev_perkil_percar']);
      }

      this.$nextTick(() => {
        this.initChartMainPerformance();
      });
    },
    initChartMainPerformance(flag) {
      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.chartRefDialog);
        }
        this.echart_dialog_instance.clear();
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.main_performance);
      }
      !flag && this.echart_instance.clear();

      let dStackingReturnData2_X = this.dStackingReturnData2_X;
      let dStackingReturnData2_JG = this.dStackingReturnData2_JG;
      let dStackingReturnData2_FH = this.dStackingReturnData2_FH;
      let dStackingReturnData2_RS = this.dStackingReturnData2_RS;

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      const max = Math.max(
        dStackingReturnData2_JG.length,
        dStackingReturnData2_FH.length,
        dStackingReturnData2_RS.length
      );
      const endValue = max - 11;
      const startValue = max;
      let options = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        // 标题设置
        title: [
          {
            text: '主要车流量概览',
            textStyle: {
              // 标题文字样式设置
              color: OPTIONDATA.title.textStyle.color,
              fontSize: OPTIONDATA.title.textStyle.fontSize,
            },
            left: 'center',
            top: OPTIONDATA.title.top,
          },
        ],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: this.ROOTFONTFIZE * (28 / 144),
          left: 'center',
          align: 'left',
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: OPTIONDATA.legend.textStyle.fontSize,
          },
          data: [
            {
              name: '当月日均收费车流量(辆次)(左轴)',
              icon: 'rect',
            },
            {
              name: '当月路费收入(万元,含增值税)(左轴)',
              icon: 'rect',
            },
            {
              name: '单日单公里单车收入(右轴)',
              icon: 'rect',
            },
          ],
        },

        grid: {
          top: this.ROOTFONTFIZE * (70 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            // console.log("args",args);
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value;
              if (node.seriesName == '当月路费收入(人民币,万元,含增值税)') {
                val = val + '万元';
              }
              if (node.seriesName == '单日单公里单车收入') {
                val = val;
              }
              str += '<br>';
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },

        xAxis: {
          type: 'category',
          data: dStackingReturnData2_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff',
            },
            onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            // rotate: 25,
            rotate: flag && this.popUpWindowIndex === 1 ? 45 : 0,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: [
          {
            // scale: true,
            position: 'left',
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              // formatter: "{value}%", //y轴百分比
              formatter: function (val) {
                // console.log("val",val);
                return val;
              },
            },
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            position: 'right',
            alignTicks: true,
            splitLine: OPTIONDATA.yAxis.splitLine,
          },
        ],
        series: [
          {
            name: '当月日均收费车流量(辆次)(左轴)',
            type: 'bar',
            data: dStackingReturnData2_JG,
            itemStyle: {
              color: '#65E7E9',
            },
            label: {
              show: false,
              position: 'inside',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: '#fefefe',
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },
          {
            name: '当月路费收入(万元,含增值税)(左轴)',
            type: 'bar',
            data: dStackingReturnData2_FH,
            itemStyle: {
              color: '#5E63E6',
            },
            label: {
              show: false,
              position: 'top',
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              color: OPTIONDATA.legend.textStyle.color,
              formatter: (value) => value.value.toFixed(2) + '%',
            },
            z: 1,
          },

          {
            name: '单日单公里单车收入(右轴)',
            yAxisIndex: 1,
            type: 'line',
            // stack: 'all',
            data: dStackingReturnData2_RS,
            itemStyle: {
              color: '#E97132',
            },
            z: 3,
            label: {
              show: false,
              position: 'top',
              fontSize:
                dStackingReturnData2_RS.length < 15
                  ? this.ROOTFONTFIZE * (14 / 144)
                  : this.ROOTFONTFIZE * (10 / 144),
              color: OPTIONDATA.legend.textStyle.color,
            },
          },
        ],
        dataZoom: {
          // 开启数据平移qc
          type: 'slider', // 滑动条单独显示
          show: this.echart_data.length > 12, // 是否显示滑动条
          filterMode: 'empty',
          startValue, // 展示区域内容的起始数值
          endValue, // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          height: this.ROOTFONTFIZE * (5 / 144), // 滑动条组件高度
          bottom: this.ROOTFONTFIZE * (2 / 144), // 距离图表区域下边的距离
          showDetail: false, // 拖拽时是否显示详情
          showDataShadow: false,
          fillerColor: '#D4D4D4', // 平移条的填充颜色
          borderColor: 'transparent', // 边框颜色
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
          // 通过该属性可以只滑动，不显示缩放功能
          handleStyle: {
            opacity: 0,
          },
          lineStyle: {
            opacity: 0,
          },
          textStyle: {
            fontSize: 0,
          },
        },
      };

      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
        return;
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.downloadImg();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_hway').click();
        }, 0);
      }
    },
    downloadImg() {
      let h = this.$refs.main_performance.scrollHeight;
      let w = this.$refs.main_performance.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.main_performance, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '主要车流量概览'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    reload() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      this.getOperationExpressway();
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
      this.echart_dialog_instance && this.echart_dialog_instance.resize();
      this.echart_instance && this.echart_instance.resize();
    },
    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChartMainPerformance(true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.initChartMainPerformance(true);
      });
    },
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
};
</script>

<style lang="less" scoped>
.main_performance {
  position: relative;
  // padding: calc(var(--ROOTFONTFIZE) * (10 / 144));
  // box-sizing: border-box;
  .title {
    width: 98%;
    display: flex;
    justify-content: flex-end;
  }
  .icon-fangda2 {
    position: absolute;
    right: 82px;
    top: 48px;
  }
}
</style>
