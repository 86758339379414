<template>
  <div class="containerCButtons">
    <span>
      <el-button v-for="(item, index) in dButtons1" :key="index" @click="fnButtons1(item)" :style="cssVars"
        :class="item == defaultVal ? 'checkedBtnStyle' : ''" class="noActive" size="mini">
        {{ item }}
      </el-button>
    </span>
  </div>
</template>

<script>
export default {
  // 从父组件传值的
  props: {
    dButtons1: {
      type: Array,
      default: ""
    },
    defaultVal: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "normal"
    },
  },
  methods: {
    fnButtons1(val) {
      this.$emit('Cbtns', val)
    }
  },
  components: {

  },
  computed: {
    cssVars() {
      if(this.size == 'mini'){
        return 'font-size:12px;'
      }
    },
  },
  data() {
    return {
    }

  }
}
</script>

<style lang="less" scoped>
.containerCButtons {

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}

span {
  background-color: var(--buttons_bgc);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0 5px;
}

.el-button {
  min-width: 101px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 30px;
}

.noActive {
  background-color: transparent;
  color: var(--buttons_noActive_color);
  border: none;
}

.checkedBtnStyle {
  background-color: var(--primary-color) !important;
  color: var(--table_bgc) !important;
}

.el-button:hover {
  background-color: var(--primary-color) !important;
  color: var(--table_bgc) !important;
}
</style>
