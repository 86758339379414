<template>
  <div class="riskRelatedCard">
    <div class="riskRelatedCard_tit">{{ overviewName }}</div>
    <div class="riskRelatedCard_ChooseArea">
      <div class="riskRelatedCard_ChooseArea_top">
        <span>
          显示类型
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep"
            class="CButtonsSeparate_style"
            :dButtons1="['大类', '个股']"
            v-bind.sync="btn1" />
        </span>
        <span v-show="btn1.dButtons1_current === '大类'">
          选择类别
          <CCascader
            key="大类"
            :default="da_lei_default_value"
            :multiple="true"
            :wide="true"
            :collapsetags="true"
            :checkStrictly="false"
            @CCascader_select_node="fnCCascader_select_val_chart3_1"
            :options="category_cascader_options">
          </CCascader>
        </span>
        <span v-show="btn1.dButtons1_current === '个股'">
          <CCascader
            key="个股"
            class="chart3_cascasder"
            :checkStrictly="false"
            :multiple="true"
            :isDelete="false"
            :wide="true"
            placeholder="请选择个股"
            :collapsetags="true"
            :default="reit_code_default"
            @CCascader_select_val="fnSelect_select_val_chart1_codename"
            :options="CODENAMEDATA"
            >选择个股
          </CCascader>
        </span>
        <span>
          <CSelect
            @CSelect_select_val="fnCSelect_chart1"
            :multiple="false"
            :clearable="false"
            :mini="true"
            v-bind.sync="select1"
            >选择类别</CSelect
          >
        </span>
        <span>
          <CTimeButtons
            @CTimeButtons_select_val="fnGetCbtnsSep2"
            class="CButtonsSeparate_style"
            :default="btn3.dButtons1_current" />
        </span>
        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads"
            @fnButtondownload="fnButtonDL6_1" />
          <span style="display: none">
            <download-excel
              id="downloadExcel_downlow"
              :data="echart_data"
              :fields="json_fields"
              :name="overviewName">
              <i class="el-icon-download"></i>
            </download-excel>
          </span>
        </div>
      </div>
    </div>
    <div class="riskRelatedCard_content">
      <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
      <div class="deadline_style ddl">数据截止日期：{{ chart_max_date }}<br /></div>
      <div
        class="downlow chartColumn1_style CHARTS_BGC"
        style="width: 100%; height: 450px"
        ref="downlowRef"
        v-loading="loading"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 1)"></div>
    </div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="downlowDialogRef" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CSelect from '@/components/Basic/CSelect';
import CCascader from '@/components/Basic/CCascader';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CDialog from '@/components/Basic/CDialog';

import html2canvas from 'html2canvas';
import { fnDownloadBgc } from '../../utils/util';

import { debounce, codesToNameMap2, categroyDefaultValue } from '../../utils';

export default {
  name: 'Downlow',
  components: { CButtonsSeparate, CSelect, CCascader, CTimeButtons, CButtonsDownload, CDialog },
  data() {
    const CATEGORYDATA = JSON.parse(localStorage.getItem('CATEGORYDATA')) || [];
    const REITsNAME = JSON.parse(localStorage.getItem('REITsNAME')) || [];
    const da_lei_default_value = categroyDefaultValue(CATEGORYDATA);
    return {
      chart_max_date: '',
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //

      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      overviewName: '最大回撤对比',
      json_fields: {},
      echart_data: [],
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      loading: true,
      dButtonsDownloads: [
        {
          id: '241020',
          name: '下载图片',
        },
        {
          id: '241020',
          name: '下载表格',
        },
      ],
      CODENAMEDATA: Object.freeze(REITsNAME),
      // 个股
      reit_code_default: codesToNameMap2(REITsNAME),
      // days: Object.freeze(['YTD', '3M', '6M', '1Y', '3Y', 'MAX']),
      // 类型
      // single type
      btn1: {
        disabled: false,
        dButtons1_current: '大类',
      },
      btn3: {
        disabled: false,
        dButtons1_current: 'YTD',
      },
      // 大类
      category_cascader_options: Object.freeze(CATEGORYDATA),
      da_lei_default_value: Object.freeze(da_lei_default_value),
      da_lei_value: [...new Set(da_lei_default_value.flat(1))],
      // 类别
      select1: {
        default: 'downlow',
        options: [
          {
            label: '最大回撤',
            value: 'downlow',
          },
          {
            label: '年化波动率',
            value: 'risk_ann',
          },
        ],
      },
      ROOTFONTFIZE: '',
      colors: {},
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.renderEchart(this.echart_data);
    },
  },
  methods: {
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.fnButtonDL6_4_1();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_downlow').click();
        }, 0);
      }
    },
    //下载图片
    fnButtonDL6_4_1() {
      let h = this.$refs.downlowRef.scrollHeight;
      let w = this.$refs.downlowRef.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.downlowRef, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = this.overviewName; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    // 表格数据
    tabelData() {
      const is_single = this.btn1.dButtons1_current === '个股';
      const type = this.select1.default;
      const json_fields = {
        category: 'category_2',
        name: 'name',
        reit_code: 'reit_code',
      };
      if (!is_single) {
        json_fields.category = 'category';
        delete json_fields.name;
        delete json_fields.reit_code;
      }
      if (type === 'risk_ann') {
        json_fields['年华波动率'] = {
          field: 'time_period',
          callback: (v = 0) => {
            return (v * 100).toFixed(2) + '%';
          },
        };
      } else if (type === 'downlow') {
        json_fields['最大回撤比'] = {
          field: 'time_period',
          callback: (v = 0) => {
            return (v * 100).toFixed(2) + '%';
          },
        };
      }
      this.json_fields = json_fields;
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    loadingData: debounce(function () {
      this.$nextTick(this._loadingData);
    }, 500),
    async _loadingData() {
      this.loading = true;
      const is_single = this.btn1.dButtons1_current === '个股';
      const queryString = new URLSearchParams({
        factor: this.select1.default,
        days: this.btn3.dButtons1_current,
        cal_function: is_single ? 'single' : 'type',
      }).toString();
      const r_type = this.da_lei_value.join('&r_type=');
      const codes = !is_single ? '' : this.reit_code.join('&codes=');
      const { data } = await this.$https.get(
        '/api/v2/get_downlow_data?' + queryString + '&r_type=' + r_type + '&codes=' + codes
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);
      let echart_data = data.data || [];
      this.data_avg = data.avg;
      this.chart_max_date = data.max_end_date || '';
      echart_data = echart_data.filter((item) => item.time_period);
      echart_data.sort((a, b) => b.time_period - a.time_period);
      this.echart_data = echart_data;
      this.renderEchart(echart_data);
      this.$nextTick(() => {
        this.tabelData();
      });
    },
    renderEchart(echart_data, flag) {
      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.downlowDialogRef);
        }
        this.echart_dialog_instance.clear();
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.downlowRef);
      }
      !flag && this.echart_instance.clear();

      // 颜色数组 不正确的数据结构浪费时间和性能
      const colors = this.$store.state.colors || [];
      this.colors = colors.reduce((p, c) => {
        p[c.name] = c.value;
        return p;
      }, {});

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      const is_single = this.btn1.dButtons1_current === '个股';
      const x_name = is_single ? 'name' : 'category';

      const colorArr = echart_data.map((item) => {
        return this.colors[item.category_2 || item.category];
      });
      const theme = this.$store.state.theme;

      const item_length = (echart_data || []).length || 40;
      const font_r = item_length > 40 ? 40 / item_length : 1;

      let line_lebel_name = '最大回撤';
      if (this.select1.default === 'risk_ann') {
        line_lebel_name = '年化波动率';
      }
      const options = {
        color: colorArr,
        dataset: [
          {
            dimensions: ['category_2', 'category', 'index', 'name', 'reit_code', 'time_period'],
            source: echart_data,
          },
        ],
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: flag && this.popUpWindowIndex === 1 ? 50 : 35,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize * font_r,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args * 100).toFixed(2) + '%';
              return args;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          triggerOn: 'mousemove|click',
          formatter: (data) => {
            const item = data[0] || {};
            return `${item.name}: <span style="margin-left:6px">${(
              item.data.time_period * 100
            ).toFixed(2)}%</span>`;
          },
        },
        series: {
          type: 'bar',
          encode: { x: x_name, y: 'time_period' },
          colorBy: 'data',
          // 平均线
          markLine: {
            symbol: ['none', 'none'],
            precision:5,
            data: [
              {
                // type: 'average',
                yAxis: this.data_avg,
              },
            ],
            silent: true,
            lineStyle: {
              type: 'dotted',
              color: theme == 'dark' ? '#fff' : '#878787',
              width: 2,
            },
            label: {
              color: theme == 'dark' ? '#b6b6b6' : '#5a4f4f',
              position: 'insideEndTop',
              fontSize: 14,
              formatter: () => {
                return `{title|${is_single ? '个股' : '大类'}平均${line_lebel_name}: }  {value|${(
                  this.data_avg * 100
                ).toFixed(2)}%} `;
              },
              rich: {
                title: {
                  color: OPTIONDATA.legend.textStyle.color,
                  fontSize: 14,
                },
                value: {
                  color: '#fff',
                  color: OPTIONDATA.xAxis.axisLabel.color,
                  fontSize: 16,
                  fontWeight: 'bolder',
                },
              },
            },
          },
          label: {
            show: true,
            position: 'top',
            fontSize: 14 * font_r,
            color: OPTIONDATA.legend.textStyle.color,
            formatter: ({ data }) => {
              return `${(data.time_period * 100).toFixed(2)}%`;
            },
          },
        },
        // 四个方向的空白
        grid: {
          top: this.ROOTFONTFIZE * (60 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
      };
      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
        return;
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
    // 选择大类
    fnCCascader_select_val_chart3_1(val) {
      this.da_lei_value = val;
      this.loadingData();
    },
    // 选择个股
    fnSelect_select_val_chart1_codename(val) {
      const data = val.filter((node) => {
        return node.includes('.');
      });
      this.reit_code = data;
      this.loadingData();
    },
    // 选择类别
    fnCSelect_chart1(val) {
      if (val === 'downlow') {
        this.overviewName = '最大回撤比';
      } else if (val === 'risk_ann') {
        this.overviewName = '年化波动率';
      }
      this.select1.default = val;
      this.loadingData();
    },
    // 选择类型
    fnGetCbtnsSep(val) {
      this.btn1.dButtons1_current = val;
      this.loadingData();
    },
    // 选择时间
    fnGetCbtnsSep2(val) {
      this.btn3.dButtons1_current = val;
      this.loadingData();
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
      return;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
  },
  created() {
    // 默认值（组件封装太垃圾，手动处理）
    this.reit_code = this.reit_code_default
      .map((item) => {
        return item.find((n) => n.indexOf('.') >= 0);
      })
      .filter((item) => item);
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
    this.$nextTick(() => {
      this.loadingData();
    });
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    this.echart_dialog_instance && this.echart_dialog_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style scoped lang="less">
.ddl {
  position: absolute;
  z-index: 5;
  left: calc(var(--ROOTFONTFIZE) * (50 / 144));
  bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  color: #fff;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  span {
    font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  }
}

.icon-fangda2 {
  right: 30px;
}

.downloadBtns {
  margin-right: 0;
  margin-left: auto;
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}
.riskRelatedCard_ChooseArea_top span,
.riskRelatedCard_ChooseArea_bottom span {
  // flex: 1;
  // min-width: 20%;
  // flex: 1;
  display: flex;
  align-items: center;
  color: var(--stock_area_item_color1);
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}
.riskRelatedCard {
  background-color: rgba(29, 31, 37, 1);
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: auto;
}

.riskRelatedCard_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
}
.riskRelatedCard_content {
  position: relative;
  padding: 0 calc(var(--ROOTFONTFIZE) * (15 / 144));
}
.chartColumn1_style {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
}
.riskRelatedCard_ChooseArea_top {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
}
</style>
