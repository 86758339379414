import { render, staticRenderFns } from "./CButtons.vue?vue&type=template&id=5647a486&scoped=true"
import script from "./CButtons.vue?vue&type=script&lang=js"
export * from "./CButtons.vue?vue&type=script&lang=js"
import style0 from "./CButtons.vue?vue&type=style&index=0&id=5647a486&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5647a486",
  null
  
)

export default component.exports